<route>
{
  "meta": {
    "auth": "lottery"
  }
}
</route>

<template>
  <div>
    <el-card class="searchContainer">
      <div class="desc">筛选查询</div>
      <el-row :gutter="20" class="query">
        <el-col :span="8">
          <span class="searchText">商品名称</span>
          <el-input
            class="searchInput"
            placeholder="请输入商品名称"
            v-model="searchValue.productName"
            clearable
            @keyup.enter.native="getList"
          >
          </el-input>
        </el-col>
        <el-col :span="8">
          <span class="searchText">抽奖状态</span>
          <el-select class="searchInput" v-model="searchValue.lotteryStatus" placeholder="请选择抽奖状态">
            <el-option label="全部" value="">全部 </el-option>
            <el-option label="未开奖" :value="0">未开奖 </el-option>
            <el-option label="已开奖" :value="1">已开奖 </el-option>
          </el-select>
        </el-col>
        <el-col :span="8">
          <span class="searchText">抽奖模式</span>
          <el-select class="searchInput" v-model="searchValue.lotteryWay" placeholder="请选择抽奖模式">
            <el-option label="全部" value="">全部 </el-option>
            <el-option label="到时间自动开奖" :value="1">到时间自动开奖 </el-option>
            <el-option label="到人数自动开奖" :value="2">到人数自动开奖 </el-option>
            <el-option label="手动开奖" :value="3">手动开奖 </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row :gutter="20" class="query">
        <el-col :span="8">
          <span class="searchText">中奖数量</span>
          <el-input
            class="searchInput"
            oninput="value=value.replace(/[^0-9]/g,'')"
            placeholder="请输入中奖数量"
            v-model="searchValue.productStock"
            clearable
            @keyup.enter.native="getList"
          />
        </el-col>

        <el-col :span="12">
          <span class="searchText">创建时间</span>
          <el-date-picker
            v-model="data"
            type="datetimerange"
            value-format="timestamp"
            :default-time="['00:00:00', '23:59:59']"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :clearable="false"
          >
          </el-date-picker>
        </el-col>
        <el-col :span="4">
          <el-button type="info" @click="resetSearch()">重置</el-button>
          <el-button type="primary" @click="getList()"> 搜索</el-button></el-col
        >
      </el-row>
    </el-card>
    <el-card class="tableContainer">
      <div slot="header" class="clearfix">
        <span>数据列表</span>
        <el-button type="success" style="float: right" @click="handleAdd">创建抽奖</el-button>

        <el-button style="float: right; margin-right: 10px" type="success" @click="downLoadExcel"
          >导出活动数据</el-button
        >
      </div>
      <div style="margin: 14px 7px" v-if="$store.getters['user/isAllArea']">
        <span class="text">活动对应城市</span>
        <el-select class="city" placeholder="请选择城市" @change="getList" v-model="areaId">
          <el-option v-if="areaId === -1" label="全国" :value="-1"></el-option>
          <el-option
            v-for="item in $store.getters['user/userAreaInfoListWithoutGlobal']"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>

      <el-table :data="list" v-loading="loading" style="width: 100%">
        <el-table-column align="center" prop="id" label="活动编号"> </el-table-column>
        <el-table-column align="left" prop="info" label="商品信息">
          <template slot-scope="scope">
            <div class="wrapper">
              <img class="avatar" :src="scope.row.productImageUrl | thumbSmall" />
              <span class="name">{{ scope.row.name }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="lotteryStatus" label="抽奖状态">
          <template slot-scope="scope">
            <span>{{ scope.row.lotteryStatus ? '已开奖' : '未开奖' }}</span>
          </template></el-table-column
        >
        <el-table-column align="center" prop="lotteryWay" label="抽奖模式">
          <template slot-scope="scope">
            <div>
              <span>{{ scope.row.lotteryWay | lotteryWay }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="productStock" label="中奖名额"> </el-table-column>
        <el-table-column align="center" label="活动创建时间">
          <template slot-scope="scope">
            <div>
              <span>{{ scope.row.createTime | formatTime }}</span>
            </div>
          </template></el-table-column
        >

        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button @click="handleShowPromoCode(scope.row)" type="text" size="mini">推广码</el-button>
            <el-button
              v-if="scope.row.lotteryWay == 3 && scope.row.lotteryStatus == 0"
              @click="openLottery(scope.row)"
              type="text"
              size="small"
            >
              开奖</el-button
            >
            <el-button
              v-if="scope.row.lotteryStatus == 0"
              @click="handleAction(scope.row, 'edit')"
              type="text"
              size="small"
            >
              编辑</el-button
            >
            <el-button @click="handleView(scope.row)" type="text" size="small"> 参与记录</el-button>
            <el-button @click="handleAction(scope.row, 'view')" type="text" size="small">查看</el-button>
            <el-button
              @click="handleRowDel(scope.row)"
              style="color: red"
              type="text"
              size="small"
              v-if="scope.row.realParticipateNum === 0 && scope.row.lotteryStatus == 0"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <el-pagination
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          class="pagination"
        >
        </el-pagination>
      </div>
    </el-card>

    <!--  -->

    <el-dialog
      :close-on-click-modal="false"
      title="导出霸王餐信息"
      :visible.sync="download.show"
      append-to-body
      width="30%"
    >
      <div style="margin-top: 10px">
        <span style="display: inline-block; width: 60px"> 时间</span>
        <el-date-picker
          value-format="timestamp"
          v-model="download.time"
          type="datetimerange"
          :default-time="['00:00:00', '23:59:59']"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="download.show = false">取 消</el-button>
        <el-button type="primary" @click="confirmDownLoadExcel">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" title="提示" :visible.sync="lotteryInfo.show" width="30%">
      <span class="assist">确认开奖</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="lotteryInfo.show = false">取 消</el-button>
        <el-button type="primary" @click="confirmBlock">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :close-on-click-modal="false" :visible.sync="isShowCode" title="推广二维码">
      <div style="display: flex; align-items: center; justify-content: center">
        <div class="miniUrl">
          <div>小程序页面路径</div>
          <canvas ref="H5canvas"></canvas>
          <div ref="copy">{{ miniUrl }}</div>
          <el-button @click="CopyUrl" type="primary">点击复制</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { pagination } from '@/mixin/pagination'
import { mapMutations } from 'vuex'
import QRCode from 'qrcode'

export default {
  name: 'lotteryList',
  mixins: [pagination],
  data() {
    return {
      areaId: -1,
      data: [this.$store.getters['global/day7Int'], this.$store.getters['global/todayInt']],
      searchValue: {
        productName: '',
        lotteryStatus: '',
        lotteryWay: '',
        productStock: '',
        createStartTime: '',
        createEndTime: ''
      },
      list: [],
      loading: false,
      lotteryInfo: { show: false, id: '' },
      miniUrl: '',
      isShowCode: false,
      wxQrcode: '',
      download: {
        show: false,
        url: '',
        time: [],
        refundReasonId: ''
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getList(true)
    })
  },
  filters: {
    lotteryWay(val) {
      if (val === 1) {
        return '指定时间开奖'
      } else if (val === 2) {
        return '指定人数开奖'
      } else if (val === 3) {
        return '手动开奖'
      }
    }
  },
  methods: {
    getPageWxQrCode({ id }) {
      //生成页面小程序码
      this.$http
        .post('boom-center-user-service/sysAdmin/wechat/create_mini_app_code_unlimit', {
          page: 'pages/luckyDraw/detail/index',
          scene: `id=${id}&shareId=1`
        })
        .then(res => {
          this.wxQrcode = res.codeUrl
        })
    },
    CopyUrl() {
      window.getSelection().removeAllRanges()
      const copyDOM = this.$refs.copy
      const range = document.createRange()
      range.selectNode(copyDOM)
      window.getSelection().addRange(range)
      try {
        let successful = document.execCommand('copy')
        this.$message.success('复制成功')
      } catch (err) {
        this.$message.error('复制失败')
      }
      window.getSelection().removeAllRanges()
    },

    downLoadExcel() {
      this.download = {
        show: true,
        url: '/boom-center-statistics-service/sysAdmin/exportExl/overlordMeal',
        time: []
      }
    },
    confirmDownLoadExcel() {
      const loading = this.$loading({
        lock: true,
        text: '正在下载，请稍候',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      let params = this.download.time.length
        ? `&&startTime=${this.download.time[0]}&&endTime=${this.download.time[1]}`
        : ''

      this.$http
        .get(this.download.url, {
          params: {
            startTime: this.download.time ? this.download.time[0] : undefined,
            endTime: this.download.time ? this.download.time[1] : undefined
          }
        })
        .then(res => {
          this.$message.success('下载中，请到导出记录页面查看')
          this.download.show = false
        })
        .catch(err => {
          this.$message.error(err.msg)
        })
        .finally(() => {
          loading.close()
        })
    },
    handleShowPromoCode(row) {
      this.isShowCode = true
      this.miniUrl = `/pages/luckyDraw/detail/index?id=${row.id}&shareId=1`
      // this.getPageWxQrCode(row)
      this.wxQrcode = `http://miniapp.boomsj.com/activity?id=${row.id}`
      this.handleShowH5Code(row)
    },
    handleShowH5Code(row) {
      this.$nextTick(() => {
        const canvas = this.$refs.H5canvas

        QRCode.toCanvas(canvas, `http://miniapp.boomsj.com/activity?id=${row.id}&shareId=1`, function(error) {
          if (error) console.error(error)
          console.log('success!')
        })
      })
    },
    ...mapMutations({ updateCurrentAreaId: 'user/updateCurrentAreaId' }),
    ...mapMutations({ updateLotteryAreaId: 'lottery/updateCurId' }),
    // 请求后端获取用户列表
    getList(val) {
      this.loading = true
      if (this.data) {
        this.searchValue.createStartTime = this.data[0]
        this.searchValue.createEndTime = this.data[1]
      } else {
        this.searchValue.createStartTime = null
        this.searchValue.createEndTime = null
      }
      this.currentPage = val === true ? this.currentPage : 1
      let params = {
        params: {
          page: this.currentPage,
          size: this.pageSize,
          ...this.searchValue
        }
      }
      this.$api.lottery
        .lottery('lotteryPage', {
          ...params,
          headers: {
            ['Bm-Area-Id']: this.areaId
          }
        })
        .then(res => {
          this.list = res.list
          this.total = parseInt(res.total)
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          this.loading = false
        })
    },
    openLottery(row) {
      this.lotteryInfo.show = true
      this.lotteryInfo.id = row.id
    },
    resetSearch() {
      this.searchValue = {
        createStartTime: this.$store.getters['global/day7Int'],
        createEndTime: this.$store.getters['global/todayInt'],
        productName: '',
        lotteryStatus: '',
        productStock: '',
        lotteryWay: '',
        page: 1,
        size: 10
      }
      this.data = [this.$store.getters['global/day7Int'], this.$store.getters['global/todayInt']]
      this.getList()
    },
    // 确认开奖
    confirmBlock() {
      this.loading = true
      this.$api.lottery
        .lottery('startLottery', this.lotteryInfo.id, {
          headers: {
            ['Bm-Area-Id']: this.$store.state.lottery.currentId
          }
        })
        .then(res => {
          this.getList(true)
          this.lotteryInfo.show = false
          this.$message.success('开奖成功')
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleAdd() {
      this.$router.push({
        path: '/lottery/activity'
      })
    },
    handleAction(row, type) {
      this.$router.push({
        path: '/lottery/activity',
        query: {
          id: row.id,
          type
        }
      })
    },
    handleView(row) {
      this.$router.push({
        path: '/lottery/record',
        query: {
          id: row.id
        }
      })
    },
    handleRowDel(row) {
      this.$confirm('确定删除改霸王餐吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      })
        .then(() => {
          this.loading = true
          this.$api.lottery
            .lottery('delLottery', row.id, {
              headers: {
                ['Bm-Area-Id']: this.$store.state.lottery.currentId
              }
            })
            .then(res => {
              this.getList(true)
              this.$message.success('删除成功')
            })
            .catch(err => {
              this.$message.error(err.msg || '操作失败')
            })
            .finally(() => {
              this.loading = false
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    }
  },
  watch: {
    areaId(nV) {
      this.updateLotteryAreaId(nV)
    }
  },
  created() {
    if (this.$store.getters['user/currentAreaId'] == -1 && this.$store.getters['user/userAreaInfoListWithoutGlobal']) {
      this.areaId = this.$store.getters['user/userAreaInfoListWithoutGlobal'][0].id
    } else {
      this.areaId = sessionStorage.currentAreaId
    }
  }
}
</script>

<style lang="less" scoped>
.platform-data {
  display: flex;
}

.searchContainer .searchInput {
  width: 180px;
}

.searchContainer .searchInputRange {
  width: 80px;
}
.query {
  margin-top: 20px;
}
.miniUrl {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  img,
  div {
    margin: 10px 0;
  }
}
.wrapper {
  display: flex;
  align-items: center;
  .avatar {
    display: inline-block;
    vertical-align: middle;
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
  .name {
    vertical-align: middle;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
</style>
